import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

declare global {
  interface Window { __fingerPrint__: any; }
}

;(async () => {
  const fp = await fpPromise
  const result = await fp.get()
  window.__fingerPrint__ = result

  console.log('__fingerPrint__', window.__fingerPrint__)
})()
