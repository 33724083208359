// [NOTE] add language , 新增語系
import en_US from './en_US.json'
import zh_CN from './zh_CN.json'
import ms_MY from './ms_MY.json'
import id_ID from './id_ID.json'
import vi_VN from './vi_VN.json'
import zh_HK from './zh_HK.json'
import hi_IN from './hi_IN.json'
import ja_JP from './ja_JP.json'
import ko_KR from './ko_KR.json'
import th_TH from './th_TH.json'
import pt_PT from './pt_PT.json'
import en_IN from './en_IN.json'
import en_PH from './en_PH.json'
import tl_PH from './tl_PH.json'

// [NOTE] add language , 新增語系
const LangList = {
  en_US,
  zh_CN,
  ms_MY,
  id_ID,
  vi_VN,
  zh_HK,
  hi_IN,
  ja_JP,
  ko_KR,
  th_TH,
  pt_PT,
  en_IN,
  en_PH,
  tl_PH,
}

export default LangList
