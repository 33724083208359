import React, { lazy, Suspense } from 'react';
import './App.css';

const Main = lazy(() => import(`./components/${process.env.REACT_APP_CLIENT}/Main`));

function App() {
  return (
    <Suspense fallback={<></>}>
      <Main />
    </Suspense>
  );
}

export default App;
